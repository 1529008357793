const jhonData = {
    "name": "Gilazov Rashid.",
    "name2": "Richard Tea.",
    "role": "",
    "ptext": "Creative Designer & Developer located in London.",
    "socialLinks":[
        {
          "name": "Facebook",
          "url": "https://www.facebook.com/rashidgilazov",
          "className": "social_facebook"
        },
        {
          "name":"twitter",
          "url":"https://twitter.com/Gilazov_com",
          "className":"social_twitter"
        },
        {
          "name":"youtube",
          "url":"https://www.youtube.com/channel/UCBptCqBP9PMykd_bF5M5Fmw",
          "className":"social_youtube"
        }
      ],
      "aboutme": "Rashid Gilazov",
      "aboutdetails": "- member of the Union Artists of Russia, the Honored Artist of Republic of Tatarstan, the Honored Academic of the International Eurasian Academy of humanitarian science of UNESCO",
      "service":[
          {
            "id" : 1,
            "iconName": "icon-profile-female",
            "serviceTitle": "Portrets",
            "sDetails" : "The people on his canvases are always different: sometimes serious and thoughtful, then enjoying life, and sometimes grieving. But in all the portraits of the painter there is precision and depth of character."
          },
          {
            "id" : 2,
            "iconName": "icon-pencil",
            "serviceTitle": "Drawings",
            "sDetails" : "During his student years, the artist drew many academic drawings."
          },
          {
            "id" : 3,
            "iconName": " icon-picture",
            "serviceTitle": "Landscapes",
            "sDetails" : "The world of images of R. Gilazov, created in landscapes — is a world of light, life-affirming, a world of high spirituality, unforgettable in the brightness of impressions."
          },
          {
            "id" : 4,
            "iconName": "icon-wine",
            "serviceTitle": "Still-life",
            "sDetails" : "Bright colors of still lifes delight with their liveliness. Sometimes even bees confuse flowers with real ones and fly to the pictures."
          },
          {
            "id" : 5,
            "iconName": "icon-presentation",
            "serviceTitle": "Teaching",
            "sDetails" : "Artist R. Gilazov is happy to share his experience with his students."
          },
          {
            "id" : 6,
            "iconName": "icon-tools",
            "serviceTitle": "Art of building",
            "sDetails" : "R. Gilazov used his talent in the design of buildings and monumental projects."
          }
      ],
      "contact":[
        {
          "id": "1",
          "title": "Contact Info",
          menuItems: [
            {
              id: 1,
              icon: 'icon_pin_alt',
              text: 'Address: 48, 3-linia V.O., Saint-Petersburg, Russia',
            },
            // {
            //   id: 2,
            //   icon: 'icon_phone',
            //   text: 'Phone: ',
            // },
            {
              id: 3,
              icon: 'icon_mail',
              text: 'Email: info@gilazov.com',
            },
            {
              id: 4,
              icon: 'icon_globe',
              text: 'Website: http://gilazov.com',
            }
          ]
        },
      ],
    contacttitle: 'Contact Form',
}

export default jhonData;

