import React, {Component} from 'react';

function Gallery () {

    return (
        
        <div id="gallery-single-section">
            <div className="isotope-wrap">
					
					<div className="isotope col-3">
						
						<div id="gallery" className="isotope-items-wrap lightgallery">

							<div className="grid-sizer"></div>


							<div className="isotope-item iso-height-1 grid-item landscape">
								<a href="./assets/img/gallery/l_1.jpg" className="gallery-single-item lg-trigger" data-exthumbnail="./assets/img/gallery/l_1.jpg" data-sub-html="<p>Yes, you can use image captions :)</p>">
									<div className="gs-item-image bg-image" style={{ backgroundImage: "url(./assets/img/gallery/l_1.jpg)", backgroundSize: "100%", backgroundPosition: 'center'}}></div>
									<div className="gsi-image-caption">Yes, you can use image captions :)</div>

									<div className="gs-item-icon"><i className="fas fa-search"></i></div>
									
								</a>

							</div>
                            <div className="isotope-item iso-height-2 grid-item portret">
								<a href="assets/img/gallery/p_1.jpg" className="gallery-single-item lg-trigger" data-exthumbnail="assets/img/gallery/p_1.jpg" data-sub-html="<p>Yes, you can use image captions :)</p>">
									<div className="gs-item-image bg-image" style={{ backgroundImage: "url(/assets/img/gallery/p_1.jpg)", backgroundSize: "auto 100%", backgroundPosition: 'center'}}></div>
									<div className="gsi-image-caption">Yes, you can use image captions :)</div>

									<div className="gs-item-icon"><i className="fas fa-search"></i></div>
									
								</a>

							</div>
                            <div className="isotope-item iso-height-1 grid-item stilllife">

								<a href="assets/img/gallery/s_4.jpg" className="gallery-single-item lg-trigger" data-exthumbnail="assets/img/gallery/s_4.jpg">

									<div className="gs-item-image bg-image" style={{ backgroundImage: "url(/assets/img/gallery/s_4.jpg)", backgroundSize: "100%", backgroundPosition: 'center'}}></div>
									<div className="gs-item-icon"><i class="fas fa-search"></i></div>
									
								</a>
							
							</div>
							
							<div className="isotope-item iso-height-1 grid-item stilllife">
								<a href="assets/img/gallery/s_5.jpg" className="gallery-single-item lg-trigger" data-exthumbnail="assets/img/gallery/s_5.jpg" data-sub-html="<p>Yes, you can use image captions :)</p>">
									<div className="gs-item-image bg-image" style={{ backgroundImage: "url(/assets/img/gallery/s_5.jpg)", backgroundSize: "100%", backgroundPosition: 'center'}}></div>
									<div className="gsi-image-caption">Yes, you can use image captions :)</div>

									<div className="gs-item-icon"><i className="fas fa-search"></i></div>
									
								</a>

							</div>
                            <div className="isotope-item iso-height-1 grid-item landscape">
								<a href="assets/img/gallery/l_7.jpg" className="gallery-single-item lg-trigger" data-exthumbnail="assets/img/gallery/l_7.jpg" data-sub-html="<p>Yes, you can use image captions :)</p>">
									<div className="gs-item-image bg-image" style={{ backgroundImage: "url(/assets/img/gallery/l_7.jpg)", backgroundSize: "100%", backgroundPosition: 'center'}}></div>
									<div className="gsi-image-caption">Yes, you can use image captions :)</div>

									<div className="gs-item-icon"><i className="fas fa-search"></i></div>
									
								</a>

							</div>
                            <div className="isotope-item iso-height-1 grid-item landscape">
								<a href="assets/img/gallery/l_2.jpg" className="gallery-single-item lg-trigger" data-exthumbnail="assets/img/gallery/l_2.jpg" data-sub-html="<p>Yes, you can use image captions :)</p>">
									<div className="gs-item-image bg-image" style={{ backgroundImage: "url(/assets/img/gallery/l_2.jpg)", backgroundSize: "100%", backgroundPosition: 'center'}}></div>
									<div className="gsi-image-caption">Yes, you can use image captions :)</div>

									<div className="gs-item-icon"><i className="fas fa-search"></i></div>
									
								</a>

							</div>
                            <div className="isotope-item iso-height-1 grid-item landscape">
								<a href="assets/img/gallery/l_6.jpg" className="gallery-single-item lg-trigger" data-exthumbnail="assets/img/gallery/l_6.jpg" data-sub-html="<p>Yes, you can use image captions :)</p>">
									<div className="gs-item-image bg-image" style={{ backgroundImage: "url(/assets/img/gallery/l_6.jpg)", backgroundSize: "100%", backgroundPosition: 'center'}}></div>
									<div className="gsi-image-caption">Yes, you can use image captions :)</div>

									<div className="gs-item-icon"><i className="fas fa-search"></i></div>
									
								</a>

							</div>
                            <div className="isotope-item iso-height-1 grid-item landscape">
								<a href="assets/img/gallery/l_4.jpg" className="gallery-single-item lg-trigger" data-exthumbnail="assets/img/gallery/l_4.jpg" data-sub-html="<p>Yes, you can use image captions :)</p>">
									<div className="gs-item-image bg-image" style={{ backgroundImage: "url(/assets/img/gallery/l_4.jpg)", backgroundSize: "100%", backgroundPosition: 'center'}}></div>
									<div className="gsi-image-caption">Yes, you can use image captions :)</div>

									<div className="gs-item-icon"><i className="fas fa-search"></i></div>
									
								</a>

							</div>
                            <div className="isotope-item iso-height-2 grid-item landscape">
								<a href="assets/img/gallery/l_5.jpg" className="gallery-single-item lg-trigger" data-exthumbnail="assets/img/gallery/l_5.jpg" data-sub-html="<p>Yes, you can use image captions :)</p>">
									<div className="gs-item-image bg-image" style={{ backgroundImage: "url(/assets/img/gallery/l_5.jpg)", backgroundSize: "auto 100%", backgroundPosition: 'center'}}></div>
									<div className="gsi-image-caption">Yes, you can use image captions :)</div>

									<div className="gs-item-icon"><i className="fas fa-search"></i></div>
									
								</a>

							</div>
                            <div className="isotope-item iso-height-1 grid-item stilllife">
								<a href="assets/img/gallery/s_1.jpg" className="gallery-single-item lg-trigger" data-exthumbnail="assets/img/gallery/s_1.jpg" data-sub-html="<p>Yes, you can use image captions :)</p>">
									<div className="gs-item-image bg-image" style={{ backgroundImage: "url(/assets/img/gallery/s_1.jpg)", backgroundSize: "100%", backgroundPosition: 'center'}}></div>
									<div className="gsi-image-caption">Yes, you can use image captions :)</div>

									<div className="gs-item-icon"><i className="fas fa-search"></i></div>
									
								</a>

							</div>
                            <div className="isotope-item iso-height-2 grid-item landscape">
								<a href="assets/img/gallery/l_3.jpg" className="gallery-single-item lg-trigger" data-exthumbnail="assets/img/gallery/l_3.jpg" data-sub-html="<p>Yes, you can use image captions :)</p>">
									<div className="gs-item-image bg-image" style={{ backgroundImage: "url(/assets/img/gallery/l_3.jpg)", backgroundSize: "auto 100%", backgroundPosition: 'center'}}></div>
									<div className="gsi-image-caption">Yes, you can use image captions :)</div>

									<div className="gs-item-icon"><i className="fas fa-search"></i></div>
									
								</a>

							</div>
                            <div className="isotope-item iso-height-1 grid-item landscape">
								<a href="assets/img/gallery/l_8.jpg" className="gallery-single-item lg-trigger" data-exthumbnail="assets/img/gallery/l_8.jpg" data-sub-html="<p>Yes, you can use image captions :)</p>">
									<div className="gs-item-image bg-image" style={{ backgroundImage: "url(/assets/img/gallery/l_8.jpg)", backgroundSize: "100%", backgroundPosition: 'center'}}></div>
									<div className="gsi-image-caption">Yes, you can use image captions :)</div>

									<div className="gs-item-icon"><i className="fas fa-search"></i></div>
									
								</a>

							</div>

						</div>
						<div className="isotope-pagination bg-main">
							<div className="iso-load-more">
								<a className="btn btn-white-bordered btn-lg" href="">View More <i className="fas fa-refresh"></i></a>
							</div>
						</div>

                </div>
			
            </div>
            
		</div>
    )}

    export default Gallery;